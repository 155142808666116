<header class="main-header">
  <div class="mobile-header">
    <div class="nav-section">
      <div class="logo">
        <img
          alt="mobile-logo"
          class="mobile-logo"
          src="assets/img/logos/nav-logo-short.svg"
        />
      </div>
      <div class="mobile-divider"></div>
      <fc-icon-button (click)="toggleNavbarState()">
        <fc-icon
          [icon]="navbarState() ? 'icon-clear' : 'icon-menu'"
          [size]="20"
          color="white"
        ></fc-icon>
      </fc-icon-button>
    </div>
    <div class="actions-section">
      <fc-filled-button (onClick)="openQuickCreationMenu()" size="small">
        <fc-icon [size]="16" color="white" icon="icon-add"></fc-icon>
      </fc-filled-button>
      <div class="mobile-divider"></div>
      <fc-icon-button (onClick)="openSearch()">
        <fc-icon [size]="20" icon="icon-search"></fc-icon>
      </fc-icon-button>
      <div class="mobile-divider"></div>
      <fc-icon-button (onClick)="openActivity()">
        <fc-icon [size]="20" icon="icon-notification"></fc-icon>
      </fc-icon-button>
      @if (currentEmployee()) {
        <div class="mobile-divider"></div>
        <ng-container *ngTemplateOutlet="employeeMenu"></ng-container>
      }
    </div>
  </div>

  <div class="desktop-header">
    <div class="header-block">
      <div class="log-container">
        <div [routerLink]="logoLink" class="logo pointer">
          <img
            class="desktop-logo"
            src="assets/img/logos/nav-logo-short.svg"
            alt="desktop-logo"
          />
        </div>
      </div>
      <div class="actions-divider m-r-20 m-l-20"></div>
      <fc-icon-button (click)="toggleNavbarState()" class="m-r-16">
        <fc-icon
          color="white"
          [size]="20"
          [icon]="navbarState() ? 'icon-clear' : 'icon-menu'"
        ></fc-icon>
      </fc-icon-button>
      <div
        class="search-block-button"
        *fcHasFeature="'search'"
        (click)="openSearch()"
      >
        <fc-icon [size]="20" icon="icon-search"></fc-icon>
        <span>Type / for search</span>
      </div>
      <fc-button-menu
        (onClick)="router.navigate(quickCreation()?.path)"
        [menuTemplate]="quickCreationMenu"
        size="small"
        >{{ quickCreation()?.name }}
      </fc-button-menu>
      <ng-template #account>
        <div cdkMenu class="menu">
          <!-- Original User -->
          @if (isHijacked && currentUser()) {
            <button cdkMenuItem class="menu-item disabled">
              <span class="menu-name"> {{ currentUser().email }} user </span>
            </button>
          }
          <!-- My account -->
          <button
            [routerLink]="['/settings/main/account']"
            cdkMenuItem
            class="menu-item"
          >
            <span class="menu-name"
              ><fc-user-avatar
                [fontSize]="10"
                [size]="20"
                [employee]="currentEmployee()"
              ></fc-user-avatar>
              My account</span
            >
          </button>
          <!-- Help center -->
          <button
            (click)="openMobileHelp()"
            cdkMenuItem
            class="menu-item sm-flex"
          >
            <div class="menu-name">
              <fc-icon [icon]="'icon-help'"></fc-icon>
              Help center
            </div>
          </button>
          <!-- news  button-->
          <button
            (click)="showNews = true"
            cdkMenuItem
            class="menu-item sm-flex"
          >
            <div class="menu-name">
              <fc-icon [icon]="'icon-news'"></fc-icon>
              News
            </div>
          </button>
          <!-- Add to screen -->
          <button
            (click)="pwaService.addToHomeScreen()"
            *ngIf="permissions.havePermissions && pwaService.deferredPrompt"
            cdkMenuItem
            class="menu-item"
          >
            <span class="menu-name"
              ><fc-icon [icon]="'icon-download'"></fc-icon> Add To Home
              Screen</span
            >
          </button>
          <!-- Developer Options -->
          @if (permissions.havePermissions) {
            <hr />
            <button
              (click)="openDeveloperOptions()"
              cdkMenuItem
              class="menu-item"
            >
              <div class="menu-name">
                <fc-icon [icon]="'icon-code'"></fc-icon>
                Developer Options
              </div>
            </button>
          }
          @if (canSwitchCustomer()) {
            <!-- Change Customer -->
            <button
              (click)="openChangeCustomer()"
              cdkMenuItem
              class="menu-item"
            >
              <div class="menu-name">
                <fc-icon icon="icon-company"></fc-icon>
                Change Customer
              </div>
            </button>
          }

          <!-- Version -->
          <hr />
          <button [text]="version" cdkMenuItem class="menu-item" fcCopyToClip>
            <div class="menu-name">
              <fc-icon [icon]="'icon-content_copy'"></fc-icon>
              <div class="flex-1 flex-row justify-between">
                <div class="menu-title">Version:</div>
                <div class="color-secondary">{{ version }}</div>
              </div>
            </div>
          </button>
          <!-- Logout -->
          <hr />
          <button (click)="openConfirmLogout()" cdkMenuItem class="menu-item">
            <span class="menu-name"
              ><fc-icon [icon]="'icon-logout'"></fc-icon> Logout</span
            >
          </button>
        </div>
      </ng-template>
      <ng-template #quickCreationMenu>
        <div cdkMenu class="menu">
          @for (menu of quickCreationNavs(); track menu) {
            <button [cdkMenuTriggerFor]="subMenu" cdkMenuItem class="menu-item">
              <div class="menu-name">
                <fc-icon [icon]="menu.icon"></fc-icon>
                <span>{{ menu.name }}</span>
              </div>
              <fc-icon icon="icon-arrow_right"></fc-icon>
            </button>

            <ng-template #subMenu>
              <div cdkMenu class="menu">
                @for (subMenu of menu.children; track subMenu) {
                  <button
                    [routerLink]="subMenu.path"
                    cdkMenuItem
                    class="menu-item"
                  >
                    <div class="menu-name">
                      <fc-icon [icon]="subMenu.icon"></fc-icon>
                      <span>{{ subMenu.name }}</span>
                    </div>
                  </button>
                }
              </div>
            </ng-template>
          }
        </div>
      </ng-template>
    </div>
    <div class="header-block actions-row">
      <div
        [class.active]="showEvents"
        (click)="openActivity()"
        class="action-block"
      >
        <fc-icon [size]="20" icon="icon-notification"></fc-icon>
        <div class="action-title">Activity</div>
      </div>
      <div class="actions-divider"></div>
      <div [class.active]="showNews" (click)="openNews()" class="action-block">
        <fc-icon [size]="20" icon="icon-news"></fc-icon>
        <div class="action-title">News</div>
      </div>
      <div class="actions-divider"></div>
      <div (click)="openMobileHelp()" class="action-block">
        <fc-icon [size]="20" icon="icon-help"></fc-icon>
        <div class="action-title">Help</div>
      </div>

      @if (currentEmployee()) {
        <div class="actions-divider"></div>
        <ng-container *ngTemplateOutlet="employeeMenu"></ng-container>
      }
    </div>
  </div>
</header>

<ng-template #employeeMenu>
  <div
    [cdkMenuTriggerFor]="account"
    cdkMenuItem
    class="account-indicator"
    [class.is-hijacked]="isHijacked"
  >
    <fc-user-avatar
      [size]="36"
      [fontSize]="12"
      [employee]="currentEmployee()"
    ></fc-user-avatar>

    @if (isHijacked && currentUser()) {
      <div class="original-user">
        <fc-avatar-placeholder
          [color]="'#FFC107'"
          [fontSize]="12"
          [size]="36"
          [text]="currentUser().email[0]"
          shape="circle"
        ></fc-avatar-placeholder>
      </div>
    }
  </div>
</ng-template>

@if (showNews) {
  <fc-side-popup title="News" (closePopup)="showNews = false">
    <fc-news-tab></fc-news-tab>
  </fc-side-popup>
  <div class="overlay" (click)="showNews = false"></div>
} @else if (showEvents) {
  <fc-side-popup title="Notifications" (closePopup)="showEvents = false">
    <fc-events-tab></fc-events-tab>
  </fc-side-popup>
  <div class="overlay" (click)="showEvents = false"></div>
}
