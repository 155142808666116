import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
} from '@angular/core';
import { CustomerSelectComponent } from './customer-select.component';
import { AuthService, Customer, CustomerApiService } from '@fc-core';
import { AsyncPipe } from '@angular/common';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'fc-dev-customer-select',
  standalone: true,
  imports: [CustomerSelectComponent, AsyncPipe],
  template: `
    <fc-customer-select
      [label]="'Choose customer'"
      [customers]="customers$ | async"
      [customersLoading]="customersLoading"
      (selectCustomer)="switchCustomer($event)"
    ></fc-customer-select>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DevCustomerSelectComponent implements OnInit {
  auth = inject(AuthService);
  customerApi = inject(CustomerApiService);
  customers$: Observable<Customer[]>;
  customersLoading = false;

  ngOnInit() {
    this.getCustomers();
  }

  getCustomers() {
    this.customersLoading = true;
    this.customers$ = this.customerApi
      .loadAll()
      .pipe(tap(() => (this.customersLoading = false)));
  }

  switchCustomer(customer: Customer) {
    this.auth.switchToCustomer(customer.id).subscribe();
  }
}
