import {
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  HostListener,
  inject,
  input,
  output,
} from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { FocusOnAppearModule } from '@fc-shared/directives/focus-on-appear/focus-on-appear.module';
import { IconButtonComponent } from '@fc-shared/ui/buttons/icon-button.component';
import { IconComponent } from '@fc-shared/ui/icon/icon.component';
import { MatDialogClose, MatDialogContent } from '@angular/material/dialog';
import { DialogHeaderComponent } from '@fc-shared/ui/dialog-opener/dialog-header.component';
import { InputModule } from '@fc-shared/ui/input/input.module';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { CustomerSelectFilterPipe } from './customer-select-filter.pipe';
import { BaseCustomer } from './base.customer';
import { ErrorComponent } from '@fc-shared/ui/input/core/error/error.component';
import { FormFieldComponent } from '@fc-shared/ui/input/core/form-field/form-field.component';
import { InputDirective } from '@fc-shared/ui/input/core/input.directive';
import { LabelComponent } from '@fc-shared/ui/input/core/label/label.component';
import { PrefixDirective } from '@fc-shared/ui/input/core/prefix.directive';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'fc-customer-select',
  standalone: true,
  imports: [
    AsyncPipe,
    FocusOnAppearModule,
    IconButtonComponent,
    IconComponent,
    MatDialogClose,
    DialogHeaderComponent,
    MatDialogContent,
    InputModule,
    ReactiveFormsModule,
    MatProgressSpinner,
    CustomerSelectFilterPipe,
    ErrorComponent,
    FormFieldComponent,
    InputDirective,
    LabelComponent,
    PrefixDirective,
  ],
  template: `
    <fc-dialog-header [title]="label()"></fc-dialog-header>
    <mat-dialog-content class="customer-select-content">
      <div class="customer-search">
        <fc-form-field class="m-b-20">
          <input
            placeholder="Search your organization"
            fcInput
            fcFocusOnAppear
            [formControl]="searchForm"
            (keydown.enter)="selectCustomer.emit(selectedCustomer)"
          />
          <fc-input-icon fcPrefix icon="icon-search"></fc-input-icon>
        </fc-form-field>
      </div>

      @if (filteredCustomers()?.length > 0) {
        <div class="customer-list">
          @for (customer of filteredCustomers(); let i = $index; track i) {
            <div
              [class.active]="selectedCustomerIndex === i"
              (click)="selectCustomer.emit(customer)"
              class="customer-list-item"
            >
              <fc-icon icon="icon-business"></fc-icon>
              <span>{{ customer.name }}</span>
            </div>
          }
        </div>
      }

      @if (filteredCustomers()?.length === 0 && !customersLoading()) {
        <div class="customer-list">No customers found</div>
      }

      @if (customersLoading()) {
        <div class="loader">
          <mat-spinner></mat-spinner>
        </div>
      }
    </mat-dialog-content>
  `,
  styles: `
    @use 'var' as *;

    .loader {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 24px;
    }

    .customer-search {
      padding: 24px 24px 16px 24px;
    }

    .customer-list {
      display: flex;
      flex-direction: column;
      gap: 12px;
      overflow: auto;
      padding: 0 24px 24px;
    }

    .customer-list-item {
      padding: 12px 20px;
      border-radius: 8px;
      border: 1px solid $secondary-100;
      background: $secondary-50;
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;

      &.active,
      &:hover {
        background: $secondary-100;
      }
    }

    mat-dialog-content.customer-select-content {
      padding: 0;
      overflow: hidden;
      display: flex;
      flex-direction: column;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerSelectComponent {
  ref = inject(ElementRef);
  searchForm = new FormControl<string>('');

  label = input<string>();
  customers = input.required<BaseCustomer[]>();
  customersLoading = input.required<boolean>();
  searchSignal = toSignal(this.searchForm.valueChanges, { initialValue: '' });
  filteredCustomers = computed(() =>
    this.customers()?.filter(
      (customer) =>
        customer.name
          .toLowerCase()
          .search(this.searchSignal().toLowerCase()) !== -1,
    ),
  );
  selectCustomer = output<BaseCustomer>();
  selectedCustomer: BaseCustomer;
  selectedCustomerIndex = 0;

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    this.selectCustomerWithArrowKey(this.filteredCustomers(), event.key);
  }

  private selectCustomerWithArrowKey(customers: BaseCustomer[], key: string) {
    let currentIndex = this.selectedCustomerIndex;
    if (customers.length > 0) {
      if (key === 'ArrowUp') {
        currentIndex = currentIndex !== 0 ? currentIndex - 1 : 0;
      } else if (key === 'ArrowDown') {
        currentIndex =
          currentIndex < customers.length - 1 ? currentIndex + 1 : currentIndex;
      } else if (key === 'Enter') {
        this.selectCustomer.emit(customers[currentIndex]);
      }
      this.selectedCustomerIndex = currentIndex;
      this.selectedCustomer = customers[currentIndex];
      this.scrollToSelectedCustomer(currentIndex);
    }
  }

  private scrollToSelectedCustomer(customerIndex: number) {
    const position = this.ref.nativeElement.querySelectorAll(
      '.customer-list-item',
    )[customerIndex]?.offsetTop;
    this.ref.nativeElement.querySelector('.customer-list').scrollTo({
      top: position - 180,
      left: 100,
      behavior: 'smooth',
    });
  }
}
